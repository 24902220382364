import { Component } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { rem, media, Color, Font, atMost } from "../../utils/style";
import mapValues from "lodash/mapValues";
import toPairs from "lodash/toPairs";
import mapboxgl from "mapboxgl";
import MagicLink from "../MagicLink";

const Wrapper = styled.div`
  background-color: #ededed;
  flex: 4;
  position: relative;
  display: flex;
  align-items: end;

  @media (min-width: 750px) {
    width: calc(100vw - 25% - 8.33333% - ((100vw - 700px) / 2));
  }

  @media (min-width: 960px) {
    width: calc(100vw - 25% - 8.33333% - ((100vw - 934px) / 2));
  }

  @media (min-width: 1200px) {
    width: calc(100vw - 25% - 8.33333% - ((100vw - 1170px) / 2));
  }

  ${media.mobile`
    margin-left: 0;
    width: 100vw;
    height: calc(100vw * 0.625);
    flex: none;
  `};
`;

const MapMarker = styled.span`
  &:hover {
    z-index: 1;
  }

  > div {
    display: block;
    width: ${rem(22)};
    height: ${rem(22)};
    border-radius: 50%;
    padding: 3px;

    &.active {
      border: 1px solid ${Color.ritualBlue};
    }

    &.large {
      width: ${rem(40)};
      height: ${rem(40)};
    }

    & > a {
      color: white;
      text-align: center;
      display: block;
      background-color: ${Color.ritualBlue};
      width: ${rem(14)};
      height: ${rem(14)};
      border-radius: 50%;
      font-size: ${rem(14)};
    }

    &.large > a {
      width: ${rem(32)};
      height: ${rem(32)};
      font-weight: 700;
    }

    &:hover {
      > a {
        color: ${Color.ritualBlue};
        background-color: ${Color.ritualYellow};
      }

      &.active {
        border: 1px solid ${Color.ritualYellow};
      }
    }
  }
`;

const MapMarkerLink = styled(MagicLink)`
  color: white;
  text-align: center;
  display: block;
  background-color: ${Color.ritualBlue};
  width: ${rem(14)};
  height: ${rem(14)};
  border-radius: 50%;
  font-size: ${rem(14)};

  &:hover {
    color: white;
  }
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  z-index: 1000;
  height: 50px;
  top: ${rem(-60)};
  text-align: center;
  background-color: ${Color.ritualYellow};
  padding: ${rem(12)} ${rem(32)};
  border-radius: 25px;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  ${Font.circular};
  color: ${Color.ritualBlue};
  white-space: nowrap;

  ${media.tablet`
    /* tooltips won't fit without reduced font */
    font-size: ${rem(14)};
  `};

  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 2147483647;
    width: ${rem(16)};
    height: ${rem(16)};
    left: calc(50% - 8px);
    transform: rotateZ(45deg);
    background-color: ${Color.ritualYellow};
  }

  ${MapMarker} > div:hover & {
    display: block;
  }

  ${media.mobile`
    display: none !important;
  `} & > .tooltip-text {
    display: inline-block;
  }
`;

const mapStyle = "mapbox://styles/ritual/cjmxq2bxb2hdg2slykbwsusw6";

export default class MapboxMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewport: {
        latitude: 42,
        longitude: 12,
        zoom: [2],
      },
      markerRefs: [],
    };

    this.map = null;
    this.mapContainer = null;
    this.markerData = this.markerData.bind(this);
    this.centerMap = this.centerMap.bind(this);
    this.getBounds = this.getBounds.bind(this);
  }

  centerMap() {
    if (this.map) {
      const bounds = this.getBounds();

      let padding;

      if (atMost.mobile()) {
        padding = 10;
      } else {
        padding = {
          top: 75,
          right: 135,
          bottom: 75,
          left: 155,
        };
      }

      this.map.fitBounds(bounds, {
        duration: 0,
        padding,
      });

      this.map.resize();
    }
  }

  getBounds() {
    const bounds = new mapboxgl.LngLatBounds();

    this.state.markerRefs.forEach((item) => {
      const { lat, lon } = item[1].location;
      // add the current coordinates to the bounds array,
      // so we can fit the map extent to the bounds of all markers
      bounds.extend([lon, lat]);
    });

    return bounds;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.centerMap);
  }

  componentDidMount() {
    window.addEventListener("resize", this.centerMap);

    // NOTE: Must require mapbox-gl at runtime since the application will fail
    // to build using node. There is a reference to an object (ie window) that
    // is not present in node that causes the build to fail.
    if (this.map == null) {
      mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

      const bounds = this.getBounds();

      this.map = new mapboxgl.Map({
        container: this.mapContainer,
        style: mapStyle,
        center: bounds.getCenter(),
        interactive: false,
        attributionControl: false,
      });

      this.map.on("load", () => {
        this.centerMap();

        this.state.markerRefs.forEach((item) => {
          const { lat, lon } = item[1].location;

          // TODO => Figure out why null marker references are filling the
          // markerRefs collection and remove them. Choosing to skip those that
          // are null for now. Null values result in default marker images.
          if (item[0] == null) return;

          new mapboxgl.Marker(item[0]).setLngLat([lon, lat]).addTo(this.map);
        });
      });
    }
  }

  markerData() {
    const { locations, locationGroup } = this.props;

    // NOTE => mapValues return an object with the following shape:
    //    {
    //      Utah: [ingredient1, ingredient2],
    //      'United Kingdom': [ingredient3]
    //    }
    //
    //  With each ingredient as an object with the following shape:
    //    {
    //      locationGroup: 'utah',
    //      ingredientLocationGroup: 'United Kingdom',
    //      locationPath = 'united-kingdom',
    //      location {
    //        lat,
    //        lon
    //      },
    //      count: 1,
    //    }
    //
    return mapValues(locations, (value, key) => {
      const ingredientLocationGroup = value[0].locationGroup;
      const locationPath = ingredientLocationGroup
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
      const ingredientNames = value.map((item) => item.name);

      let tooltip = "";

      if (ingredientNames.length > 2) {
        tooltip =
          ingredientNames.slice(0, ingredientNames.length - 1).join(", ") +
          " & " +
          ingredientNames.slice(-1);
      } else {
        tooltip = ingredientNames.join(" & ");
      }

      return {
        locationGroup,
        ingredientLocationGroup,
        locationPath,
        location: value[0].location,
        count: value.length,
        tooltip,
      };
    });
  }

  render() {
    const { locationGroup, product } = this.props;
    const markers = this.markerData();

    const basePath = product
      ? `/products/${product.slug}/ingredients/map`
      : "/ingredients/map";

    /* eslint-disable react/no-direct-mutation-state */
    this.state.markerRefs = [];

    return (
      <Wrapper>
        <div className="w-100 h-100" ref={(x) => (this.mapContainer = x)} />
        <div style={{ display: "none" }}>
          {toPairs(markers).map((item, i) => {
            const {
              ingredientLocationGroup,
              locationPath,
              count,
              tooltip,
            } = item[1];
            const classNames = [];

            if (count > 1) classNames.push("large");
            if (locationGroup === ingredientLocationGroup)
              classNames.push("active");

            return (
              <MapMarker
                key={`marker-${i}`}
                ref={(r) => {
                  this.state.markerRefs.push([
                    ReactDOM.findDOMNode(r),
                    item[1],
                  ]);
                }}
              >
                <div className={classNames.join(" ")}>
                  <MapMarkerLink
                    to={`${basePath}/${locationPath}`}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span>{count > 1 ? count : null}</span>
                    <Tooltip>
                      <span className="tooltip-text">{tooltip}</span>
                    </Tooltip>
                  </MapMarkerLink>
                </div>
              </MapMarker>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}
